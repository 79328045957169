@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    scroll-behavior: smooth;
}

body {
    @apply text-lg
}

.projects img {
    width: 100%;
}

/* color */
.bg-primary {
    background: #27272A;
}

.bg-secondary {
    background: #3c3c3f;
}

.bg-accent {
    background: #7477FF;
}

.text-accent {
    color: #EDEDFC;
}


nav li a {
    @apply px-4 py-5 text-lg;
}

nav li a:hover {
    color: #0047ab;
}

.mobile-nav {
    @apply block md:hidden fixed top-10 py-2 w-full h-full bg-gray-900 duration-500;
}


/* hero */
h1 {

    font-family: 'Patua One', cursive;

    line-height: 1.5 !important;
  }

  p {
    font-family: 'poppins', cursive;
    
  }

  a {
    font-family: 'poppins', cursive;
    
  }